<script setup lang="ts">
import { useSSRContext, Fragment, getServerState } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      serviceUrls: undefined,
    })
</script>

<template>
  <div :class="`theme--${ctx?.brand}`">
    <div class="noprint">
      <fragment
        render-mode="server"
        :when-visible="false"
        :url="ctx?.serviceUrls?.header ?? ''"
        :headers="{ country: ctx?.market, market: ctx?.market, brand: ctx?.brand }"
      />
    </div>
    <div class="main">
      <slot />
    </div>
    <div class="noprint">
      <fragment
        render-mode="server"
        :url="ctx?.serviceUrls?.footer ?? ''"
        :headers="{ country: ctx?.market, market: ctx?.market, brand: ctx?.brand }"
      />
    </div>
  </div>
</template>

<style scoped>
  .main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: var(--main-page--top-padding, 3.5rem);
  }

  @media print {
    .noprint {
      display: none;
    }

    main {
      padding-top: 0;
    }
  }
</style>

// usePageData.ts
import { ref, onServerPrefetch, onBeforeMount } from 'vue'
import { addServerState, getServerState, useRoute, getLogger, setStatusCode } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import type { Brand, ZmartaAnalytics } from '@ocp-zmarta/content-sdk'
import {
  getPage, type Page, useZmartaAnalytics, type Site, type Market, AvailableSites, type CustomData,
  ErrorPageUrls, HttpStatusCode,
} from '@ocp-zmarta/content-sdk'
import { isExcludedRoute } from '../../utils'
import type { SSRContext } from 'vue/server-renderer'

interface PageDataOptions {
  ctx?: SSRContext | undefined
  customData?: CustomData[]
}

function setErrorPageStatusCodeFor (pagePath: ErrorPageUrls) {
  switch (pagePath) {
    case ErrorPageUrls.NotFound:
      setStatusCode(HttpStatusCode.NotFound)
      break
    case ErrorPageUrls.InternalServerError:
      setStatusCode(HttpStatusCode.InternalServerError)
      break
  }
}

export default function ({ ctx, customData }: PageDataOptions = {}) {
  const route = useRoute()
  const pageData = ref<Page>()
  const site = ref<Site>()
  const ZGA = ref<ZmartaAnalytics>()
  const logger = getLogger()

  if (isExcludedRoute(route?.pathname ?? '')) return {}

  onServerPrefetch(async () => {
    const path = route?.pathname ?? ''
    site.value = `${ctx?.brand}.${ctx?.market}` as Site

    if (!path) logger.error('Path is undefined')
    if (!site.value) logger.error('Site is undefined')

    try {
      pageData.value = await getPage({ path, site: site.value }, { customData })
    } catch (error: any) {
      setErrorPageStatusCodeFor('/500-error' as ErrorPageUrls)
      logger.error('Error in getPage fetcher', { error, path, site: site.value })
    }

    setErrorPageStatusCodeFor(pageData.value?.path as ErrorPageUrls)

    // Set the values on the server state
    addServerState('page-data', pageData.value)
    addServerState('site', site.value)
    addServerState('query', ctx?.query)
  })

  onBeforeMount(() => {
    // Retrieve values from the server state
    pageData.value = getServerState('page-data')!
    site.value = getServerState('site') ?? '' as Site

    if (AvailableSites.includes(site.value)) {
      const market = site.value.split('.').pop() as Market
      const brand = site.value.split('.').shift() as Brand
      ZGA.value = useZmartaAnalytics({
        brand,
        market,
      })

      const serverState: { url: string | undefined, previousUrl: string | undefined } = getServerState('query')!
      const url = serverState.url ?? route?.pathname
      const previousUrl = serverState.previousUrl ?? ''

      if (url === ErrorPageUrls.NotFound) {
        void ZGA.value.event.page?.error404Page({ url, previousUrl })
      }
    }
  })

  return {
    pageData,
    site,
  }
}
